<template>
  <div>
    <v-row class="hidden-md-and-up mobile-header no-gutters">
      <v-col cols="6" class="text-center mobile-header-item" :class="transactionObj.transactionType.toLowerCase() == 'deposit' ? 'selected' : ''" @click="naviToDeposit">
        <router-link :to="routeName.DEPOSIT" style="text-decoration: unset !important">
          <span>{{ $t(`label.deposit`) }}</span>
        </router-link>
      </v-col>
      <v-col
        cols="6"
        class="text-center mobile-header-item"
        :class="transactionObj.transactionType.toLowerCase() == 'withdrawal' ? 'selected' : ''"
        :to="{ name: routeName.WITHDRAWAL }"
        @click="naviToWithdrawal"
      >
        <router-link :to="routeName.WITHDRAWAL" style="text-decoration: unset !important">
          <span>{{ $t(`label.withdrawal`) }}</span>
        </router-link>
      </v-col>
    </v-row>
    <v-card class="desktop_card">
      <v-card-title class="hidden-sm-and-down">
        <label class="--v-primary-base text-left text-capitalize mr-4">{{ $t(`label.${transactionObj.transactionType.toLowerCase()}`) }}</label>
      </v-card-title>
      <v-card class="bank-detail-card deposit-card" elevation="0">
        <div>
          <v-row no-gutters align="end" class="px-8 pt-4">
            <v-col cols="auto">
              <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                {{ $t(`field.paymentType`) }}
                <span class="red--text ml-1">*</span>
              </label>
              <span class="red--text ml-1" v-if="selectedMethod === '' || selectedMethod == null">{{ $t(`fieldErrorMessage.methodRequired`) }}</span>

              <v-row no-gutters class="py-2" :style="$vuetify.breakpoint.xsOnly ? 'gap:.5rem;' : 'gap:.8rem;'">
                <v-col cols="auto" v-for="(method, index) in availableMethods" :key="index">
                  <v-btn
                    :value="method.code"
                    :input-value="transactionObj.methodCode == method.code"
                    :class="index == 0 ? 'pa-2 gateway-button' : $vuetify.breakpoint.xsOnly ? ' pa-2 gateway-button' : ' pa-2 gateway-button'"
                    :active-class="'active-gateway-button'"
                    @click="changeDepositMethod(method, true)"
                    height="100%"
                    width="100px"
                    outlined
                  >
                    <img :src="method.code == 'NLB' ? `/static/image/icon/local-bank.png` : `/static/svg/deposit-ewallet-${method.code.toLowerCase()}.svg`" />
                    <v-chip class="percent-label ma-0 pa-1" label color="#fddd04" x-small v-if="depositPercentage != false && method.code != 'BKASH' && method.code != 'NLB'">
                      + {{ depositPercentage }} %
                    </v-chip>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- New Local Bank -->
          <v-form ref="transactionForm" v-if="transactionObj.methodCode == 'NLB'">
            <v-row no-gutters align="end" class="px-8 pt-4 pb-2">
              <v-col lg="12" md="8">
                <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                  {{ $t(`field.depositChannel`) }}
                  <span class="red--text ml-1">*</span>
                </label>
                <v-row no-gutters :class="$vuetify.breakpoint.mdAndDown ? 'py-2 bank-code-mobile' : 'py-2 bank-code'">
                  <v-col cols="auto" v-for="(g, k) in gatewayChannel" :key="k" class="mr-2">
                    <v-btn
                      :value="g.gatewayCode"
                      :input-value="transactionObj.localAccountNumber == g.bankAccount.account_number && transactionObj.localAccountName == g.bankAccount.account_name"
                      height="auto"
                      @click="changeLocalBankMethod(g)"
                      :active-class="'active-gateway-button'"
                      outlined
                      depressed
                      :class="'pa-2 mr-2 theme-button font-weight-bold pointer-cursor'"
                    >
                      <div v-if="g.gatewayCode == 'FAST'" class="tag-recommond"></div>
                      <label v-if="g.gatewayCode != 'FAST'" class="gateway_name">{{ g.gatewayName }} ( {{ g.bankAccount.bank_account_code }} )</label>
                      <img v-else class="channelBtnImg" src="/static/image/icon/fastpay.png" />
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="$vuetify.breakpoint.mdAndDown ? 'col-12' : 'col-8'">
                    <label class="input-field-label ma-0 text-capitalize d-block" style="font-size: 16px" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                      {{ $t(`field.depositInfo`) }}
                    </label>
                    <v-row class="pay-info-group mt-2" style="padding-left: 12px !important;">
                      <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-card-mobile card-blue col-11' : 'bank-card card-blue col-7'">
                        <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-name pb-0' : 'bank-name'">
                          <div :class="$vuetify.breakpoint.mdAndDown ? 'bank-info-copy pl-0 display-box col-6' : 'bank-info-copy pl-0 display-box col-4'">
                            <p class="localBankDetail_branch">{{ transactionObj.merchantLocalBankCode }}</p>
                            <v-icon class="point icon-color" @click="copyTextGatewayCode()">content_copy</v-icon>
                          </div>
                        </v-col>
                        <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-account-mobile bank-info-copy display-box col-11' : 'bank-account bank-info-copy display-box col-11'">
                          <p class="localBankDetail_accountNo">{{ transactionObj.localAccountNumber }}</p>
                          <v-icon class="point icon-color" @click="copyTextAccountNumber()">content_copy</v-icon>
                        </v-col>
                        <v-col :class="$vuetify.breakpoint.mdAndDown ? 'bank-member bank-info-copy display-box col-11' : 'bank-member bank-info-copy display-box col-8'">
                          <p class="localBankDetail_accountName">{{ transactionObj.localAccountName }}</p>
                          <v-icon class="point icon-color" @click="copyTextAccountName()">content_copy</v-icon>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div>
              <v-row no-gutters align="end" class="px-8 mt-6">
                <v-col cols="12" lg="4" md="5">
                  <div>
                    <app-form-field
                      v-model.trim="transactionObj.memberBankAccountId"
                      :label="$t(`label.yourBankAccountDetails`)"
                      :hint="$t(`fieldHint.memberBank`)"
                      type="select"
                      :rules="validator.dropDownListRules()"
                      :items="memberBankAccounts"
                      :change="this.updateSelectedMemberBankAccount"
                      :displayText="this.memberBankAccountDisplayText"
                      :enabledLabel="true"
                    ></app-form-field>
                  </div>
                  <div class="pb-4" v-if="transactionObj.memberBankAccountId">
                    <v-row no-gutters justify="center">
                      <v-card class="bank-account-card" elevation="0">
                        <div class="pa-0 px-2 py-2">
                          <v-card-text class="pa-0 px-2 body-1 navigator_active--text font-weight-bold">{{ transactionObj.memberBankName }}({{ transactionObj.memberBankCode }})</v-card-text>
                          <v-card-text class="pa-0 px-2 body-1 navigator_active--text font-weight-bold">
                            {{ transactionObj.memberBankAccountNumber }}
                          </v-card-text>
                          <v-card-text class="pa-0 px-2">{{ transactionObj.memberBankAccountName }}</v-card-text>
                        </div>
                      </v-card>
                    </v-row>
                  </div>
                  <div class="pb-2 text-center" v-if="false">
                    <v-btn text :ripple="false" class="text-uppercase font-weight-bold text-center navigator_active--text text-decoration-underline" @click="openNewBankAccountDialog">
                      {{ $t(transactionObj.memberBankAccountId != '' ? `label.changeBankAccount` : `label.addBankAccount`) }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-row no-gutters align="end" class="px-8 pb-2">
              <v-col cols="12" lg="4" md="5">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <div>
              <v-row no-gutters align="end" class="px-8">
                <v-col lg="4" md="5">
                  <app-form-field :enabledLabel="true" :label="$t(`field.tranId`)" :clearable="true" v-model.trim="transactionObj.transactionId" :rules="validator.requiredRules()"></app-form-field>
                </v-col>
              </v-row>
              <v-row no-gutters align="end" class="px-8">
                <v-col cols="12">
                  <label class="input-field-label ma-0 text-capitalize d-block pb-2">
                    {{ $t(`fieldHint.uploadReceipt`) }}
                    <span class="red--text ml-1">*</span>
                  </label>
                </v-col>
                <v-col cols="12" lg="4" md="5">
                  <v-file-input
                    ref="fileimage"
                    accept="image/*"
                    :label="stringFormat('{0}', $t(`field.attachment`))"
                    class="input-field"
                    prepend-icon
                    outlined
                    :rules="transactionObj.rawAttachment != null && transactionObj.rawAttachment != undefined ? validator.depositAttachmentRules() : validator.requiredRules()"
                    @change="this.uploadDepositAttachement"
                    v-model="transactionObj.rawAttachment"
                    clearable
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>
            <v-form ref="depositForm">
              <v-row no-gutters align="end" class="px-8 pt-1 pb-3">
                <v-col lg="4" md="5">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters align="end" class="px-8 pt-4 mt-0">
                <v-col lg="4" md="5">
                  <v-row no-gutters>
                    <v-col cols="4" class="pr-2 pb-2" v-for="(i, k) in depositAmounts" :key="k">
                      <v-btn
                        height="auto"
                        @click="applyDepositAmount(i)"
                        color="black"
                        depressed
                        :input-value="transactionObj.amount == i"
                        class="pa-2 full-width theme-button font-weight-bold yellow--text"
                        :active-class="'active-amount-button'"
                      >
                        {{ i }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters align="end" class="px-8 pt-4">
                <v-col lg="4" md="5">
                  <app-form-field
                    :tooltip="
                      selectedMethod != ''
                        ? stringFormat($t(`fieldHint.depositAmount`), currencyFormatter(minAmount, transactionObj.currency), currencyFormatter(maxAmount, transactionObj.currency))
                        : ''
                    "
                    :placeholder="
                      selectedMethod != '' ? stringFormat($t(`fieldHint.minMax`), currencyFormatter(minAmount, transactionObj.currency), currencyFormatter(maxAmount, transactionObj.currency)) : ''
                    "
                    :enabledLabel="true"
                    v-model="transactionObj.amount"
                    :change="this.updateMoneyAmount"
                    :keyup="this.checkDepostAmount"
                    :label="$t(`field.depositAmount`)"
                    :rules="validator.depositAmountRules(2, minAmount, maxAmount, transactionObj.currency)"
                  ></app-form-field>
                </v-col>
              </v-row>
            </v-form>
            <v-form ref="bonusForm">
              <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8 pt-1 pb-3">
                <v-col lg="4" md="5">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8">
                <v-col lg="4" md="5">
                  <app-form-field
                    v-model.trim="bonusObj.id"
                    :enabledLabel="true"
                    :label="$t(`field.depositBonus`)"
                    type="select"
                    :items="bonusList"
                    :displayText="this.getBonusDisplayText"
                    :change="this.checkDepositAmount"
                    :rules="bonusRequiredDepositAmount != '' ? validator.bonusRequiredDepositAmountRules(bonusRequiredDepositAmount, transactionObj.amount, transactionObj.currency) : []"
                    :clearable="true"
                  ></app-form-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8 pb-5">
              <v-col lg="4" md="5">
                <app-button class="dialog-button deposit-btn" :title="$t(`label.deposit`)" :action="this.showConfirmationDialog" :disabled="!isDepositActive"></app-button>
              </v-col>
            </v-row>
          </v-form>

          <!-- End New Local Bank -->

          <!-- Online -->
          <v-form ref="transactionForm" v-if="transactionObj.methodCode != 'NLB'">
            <v-row no-gutters align="end" class="px-8 pt-4 pb-2">
              <v-col lg="6" md="5">
                <label class="input-field-label ma-0 text-capitalize d-block" :class="$vuetify.breakpoint.mdAndDown ? 'pb-1' : 'pb-2'">
                  {{ $t(`field.depositChannel`) }}
                  <span class="red--text ml-1">*</span>
                </label>
                <v-row no-gutters class="py-2">
                  <v-col cols="auto" v-for="(g, k) in gatewayChannel" :key="k" class="mr-2">
                    <v-btn
                      :value="g.gatewayCode"
                      :input-value="transactionObj.gatewayProviderCode == g.gatewayCode"
                      height="auto"
                      @click="changeDepositChannel(g.gatewayCode)"
                      :active-class="'active-gateway-button'"
                      outlined
                      depressed
                      class="pa-2 theme-button font-weight-bold"
                    >
                      <div v-if="g.gatewayCode == 'FAST'" class="tag-recommond"></div>
                      <label v-if="g.gatewayCode != 'FAST'" class="gateway_name">{{ g.gatewayName }}</label>
                      <img v-else class="channelBtnImg" src="/static/image/icon/fastpay.png" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col lg="4" md="5">
                <v-row no-gutters>
                  <v-col cols="4" class="pr-2 pb-2" v-for="(i, k) in depositAmounts" :key="k">
                    <v-btn
                      height="auto"
                      @click="applyDepositAmount(i)"
                      color="black"
                      depressed
                      :input-value="transactionObj.amount == i"
                      class="pa-2 full-width theme-button font-weight-bold yellow--text"
                      :active-class="'active-amount-button'"
                    >
                      {{ i }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col lg="4" md="5">
                <app-form-field
                  :tooltip="
                    selectedMethod != ''
                      ? stringFormat($t(`fieldHint.depositAmount`), currencyFormatter(minAmount, transactionObj.currency), currencyFormatter(maxAmount, transactionObj.currency))
                      : ''
                  "
                  :placeholder="
                    selectedMethod != '' ? stringFormat($t(`fieldHint.minMax`), currencyFormatter(minAmount, transactionObj.currency), currencyFormatter(maxAmount, transactionObj.currency)) : ''
                  "
                  :enabledLabel="true"
                  v-model="transactionObj.amount"
                  :change="this.updateMoneyAmount"
                  @keydown.enter.prevent
                  :label="$t(`field.depositAmount`)"
                  :rules="validator.depositAmountRules(2, minAmount, maxAmount, transactionObj.currency)"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-form ref="bonusForm">
              <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8 pt-1 pb-3">
                <v-col lg="4" md="5">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8">
                <v-col lg="4" md="5">
                  <app-form-field
                    v-model.trim="bonusObj.id"
                    :enabledLabel="true"
                    :label="$t(`field.depositBonus`)"
                    type="select"
                    :items="bonusList"
                    :displayText="this.getBonusDisplayText"
                    :change="this.checkDepositAmount"
                    :rules="bonusRequiredDepositAmount != '' ? validator.bonusRequiredDepositAmountRules(bonusRequiredDepositAmount, transactionObj.amount, transactionObj.currency) : []"
                    :clearable="true"
                  ></app-form-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row no-gutters v-if="bonusList.length > 0" align="end" class="px-8 pb-5">
              <v-col lg="4" md="5">
                <app-button
                  class="dialog-button "
                  customClass="deposit-btn buttonPrimary theme-button"
                  :title="$t(`label.deposit`)"
                  :action="this.showConfirmationDialog"
                  :disabled="!isDepositActive"
                ></app-button>
              </v-col>
            </v-row>
          </v-form>

          <!-- End Online -->
        </div>
      </v-card>
    </v-card>
    <app-dialog :dialogShow="newBankAccountDialogShow" :max-width="600" :title="$t(`label.bankAccount`)" :closeAction="this.closeNewBankAccountDialog">
      <app-account
        :showFullBankForm="true"
        :banks="banks"
        :bankAccountDetail="bankAccountDetail"
        :closeNewBankAccountDialog="this.closeNewBankAccountDialog"
        @updateBankAccountObject="updateBankAccountData"
      ></app-account>
    </app-dialog>
    <app-dialog
      :dialogShow="msgDialog.show"
      ref="depositConfirmationDialog"
      :max-width="400"
      :title="$t(`label.depositDetails`)"
      :header_title="$t(`label.depositDetails`)"
      :closeAction="this.onCloseClick"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="6">
          <label class="payment-dialog-title">{{ $t(`field.depositAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right">
          {{ transactionObj.amount | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="6" class="mt-2">
          <label class="payment-dialog-title">{{ $t(`field.bonusAmount`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2">
          {{ bonusAmount | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="6" class="mt-2 mb-6">
          <label class="payment-dialog-title">{{ $t(`field.targetTurnover`) }}</label>
        </v-col>
        <v-col cols="6" class="text-right mt-2 mb-6">
          {{ bonusTurnOver | currency(transactionObj.currency) }}
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="submitTransaction" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
    <app-dialog
      :dialogShow="showDepositUnavailableDialog"
      ref="depositUnavailableDialog"
      :max-width="400"
      :title="$t(`label.system`)"
      :header_title="$t(`label.system`)"
      :closeAction="this.routeToHome"
    >
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5'">
        <v-col cols="12" class="text-center">
          <p>{{ $t(`message.unableDeposit`) }}</p>
        </v-col>
        <v-col cols="12" class="text-center">
          <p>
            {{ $t(`message.unableDeposit_1`) }}
            <br />
            {{ $t(`message.unableDeposit_2`) }}
          </p>
        </v-col>
        <v-col cols="8">
          <v-btn height="auto" @click="routeToHome()" color="black" depressed class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text">
            {{ $t(`label.confirm`) }}
          </v-btn>
        </v-col>
      </v-row>
    </app-dialog>
  </div>
</template>

<script>
import NavFastPay from '@/assets/svg/fast_pay.svg'
import currencyFormat from '@/filters/currency-format'
import { DataType, DevicePlatform, ProcessTransactionResponseType } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import VueQrCode from 'vue-qrcode-component'
import format from 'string-format'
import AppAccount from '@/components/bank/account.vue'
import { SHARED, SESSION, API } from '@/constants/constants'
import { formValidator, locale, uiHelper, errorCodeHelper } from '@/util'
import { MEMBER_CHECK_TOKEN_VALIDITY } from '@/store/member.module'
import {
  PAYMENT_MERCHANT_BANK_ACCOUNT,
  PAYMENT_SUBMIT_PAYMENT_TRANSACTION,
  PAYMENT_AVAILABLE_METHOD,
  PAYMENT_MEMBER_BANK_ACCOUNT,
  PAYMENT_RESET_TRANSACTION_RESPONSE_STORE,
  PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK,
  PAYMENT_PROCESS_TRANSACTION,
  PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE,
  DEPOSIT_PERCENTAGE_CONFIGURATION
} from '@/store/payment.module'
import { BONUS_LIST, BONUS_APPLY_BONUS, BONUS_RESET_APPLY_BONUS_RESPONSE_STORE } from '@/store/bonus.module'
import { REWARD_CONFIGURATION_STATUS } from '@/store/reward.module'
import InstantPopupDialog from '@/components/InstantPopupDialog.vue'

export default {
  name: 'deposit',
  metaInfo: {
    title: `${locale.getMessage('meta.deposit_title')}`,
    titleTemplate: `%s | ${uiHelper.getHost()}`,
    meta: [
      {
        charset: 'utf-8'
      },
      {
        name: 'description',
        content: `${locale.getMessage('meta.deposit_description')}`
      },
      {
        name: 'keyword',
        content: `${locale.getMessage('meta.deposit_keyword')}`
      }
    ]
  },
  components: {
    InstantPopupDialog,
    AppAccount,
    VueQrCode,
    NavFastPay
  },
  data: () => ({
    routeName: ROUTE_NAME,
    depositAmounts: [],
    bankAccountDetail: {
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberBankName: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      saveNewBank: false,
      memberCode: localStorage.getItem(SESSION.USERNAME),
      isUpdateBank: false
    },
    bonusTransactionAmount: 0,
    bonusTransactionId: 0,
    depositDatetimeMaxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
    currencyFormatter: currencyFormat,
    dataType: DataType,
    selectedMethod: '',
    gatewayChannel: [],
    selectedLocalBank: '',
    currentLanguage: uiHelper.getLanguage(),
    depositingBankFieldRequired: false,
    memberBankInfoRequired: false,
    newBankAccountDialogShow: false,
    localBankDepositStep: 1,
    stringFormat: format,
    validator: formValidator,
    bonusObj: {
      id: ''
    },
    selectedBankCodeValue: '',
    merchanctBankAccountNumber: '',
    tempMemberBankAccountId: '',
    minAmount: 200,
    maxAmount: 25000,
    setAmount: 0,
    transactionObj: {
      /* LOCAL BANK INFO */
      gatewayName: '',
      localAccountName: '',
      localAccountNumber: '',
      localAccountCode: '',
      /* TRANSACTION INFO */
      transactionId: '',
      accountName: '',
      transactionType: SHARED.DEPOSIT,
      methodCode: '',
      amount: '',
      depositDateTime: '',
      attachmentFileType: '',
      attachment: '',
      rawAttachment: undefined,
      ipAddress: '',
      saveNewBank: false,
      gatewayProviderCode: '',
      gatewayBankAccountCode: '',
      bankAccount: '',
      /* MEMBER INFO */
      memberCode: localStorage.getItem(SESSION.USERNAME),
      memberRealName: '',
      currency: localStorage.getItem(SESSION.CURRENCY),
      memberBankAccountId: '',
      memberBankAccountName: '',
      memberBankAccountNumber: '',
      memberAccountBankCode: '',
      memberBankName: '',
      memberBankCode: '',
      memberBankProvince: '',
      memberBankBranch: '',
      memberBankCity: '',
      memberEmail: '',
      memberContactIdorNumber: '',
      /* MERCHANT or ONLINE GATEWAY INFO */
      merchantBankCode: '',
      merchantLocalBankCode: '',
      gatewayAccountId: '',
      gatewayAccountBankId: '',
      /* OTHER */
      transactionSuccessUrl: uiHelper.getHostname(),
      oriCurrency: '',
      oriAmount: '',
      language: uiHelper.getLanguage(),
      platform: uiHelper.getPlatform()
    },
    /* localBank: {
      gatewayName: '',
      gatewayCode: '',
      accountName: '',
      accountNumber: '',
      accountCode: ''
    },*/
    transactionResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    applyBonusResponseResult: {
      success: false,
      complete: false,
      code: 0
    },
    qrCodeUrl: '',
    bonusRequiredDepositAmount: '',
    submittedTransactionResponse: null,
    msgDialog: {
      show: false,
      title: '',
      msg: ''
    },
    bonusAmount: 0,
    bonusTurnOver: 0,
    showDepositUnavailableDialog: false,
    isDepositActive: true
  }),
  computed: {
    applyBonusResponseComplete() {
      return this.$store.state.bonus.applyBonusResponse.complete
    },
    depositAmount() {
      return this.transactionObj.amount
    },
    banks() {
      return this.$store.state.payment.banks
    },
    availableMethods() {
      let k = this.$store.state.payment.availableDepositMethods
      try {
        // return k.sort((a, b) => a.name.localeCompare(b.name))
        return k
      } catch (err) {
        return k
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    merchantBankAccounts() {
      // return this.$store.state.payment.merchantBankAccounts
      return this.selectedMethod.bankAccounts
    },
    memberBankAccounts() {
      return this.$store.state.payment.memberBankAccounts
    },
    bonusList() {
      let bonusList = _.cloneDeep(this.$store.state.bonus.bonusList)
      let nobonus = {
        name: 'No Bonus',
        value: '',
        locale: [],
        memberGroup: []
      }
      bonusList = [nobonus, ...bonusList]
      return bonusList
    },
    transactionResponseComplete() {
      return this.$store.state.payment.trasnactionResponse.complete
    },
    onlinePaymentSupportedBanks() {
      // return this.$store.state.payment.onlinePaymentSupportedBanks
      return this.selectedMethod.banks
    },
    processTransactionResponseComplete() {
      return this.$store.state.payment.processTransactionResponse.complete
    },
    depositPercentage() {
      return this.$store.state.payment.depositPercentageConfiguration
    }
  },
  watch: {
    depositPercentage() {
      let i = this.$store.state.payment.depositPercentageConfiguration
      return i
    },
    memberBankAccounts() {
      //this.preSelectMemberBankAccount()
    },
    merchantBankAccounts() {
      // return this.$store.state.payment.merchantBankAccounts
      let i = this.selectedMethod.bankAccounts
      return i
    },
    applyBonusResponseComplete() {
      let response = this.$store.state.bonus.applyBonusResponse
      if (response.complete) {
        try {
          this.$ga.event(
            'Bonus',
            `Apply Bonus`,
            `Bonus ID - ${this.bonusTransactionId} - ${response.success ? 'Success' : 'Fail'}${!response.success ? ` [Error : ${response.code}]` : ''}`,
            response.success ? parseFloat(this.bonusTransactionAmount) : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.bonusTransactionAmount = 0
        this.bonusTransactionId = 0
        this.applyBonusResponseResult = {
          success: response.success,
          complete: response.complete,
          code: response.code
        }
        this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
      }
    },
    availableMethods() {
      let depositActive = this.$store.state.payment.availableDepositMethods.find(method => {
        return method.isActive
      })
      this.isDepositActive = depositActive != undefined
      if (!this.isDepositActive) {
        this.showDepositUnavailableDialog = true
      }
      this.changeDepositMethod(this.$store.state.payment.availableDepositMethods[0])
    },
    transactionResponseComplete() {
      let transactionResponse = this.$store.state.payment.trasnactionResponse
      if (transactionResponse.complete) {
        try {
          this.$ga.event(
            'Payment',
            `Deposit`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${transactionResponse.success ? 'Success' : 'Fail'}${!transactionResponse.success ? ` [Error : ${transactionResponse.code}]` : ''}`,
            transactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.transactionComplete(transactionResponse)
      }
    },
    processTransactionResponseComplete() {
      let processTransactionResponse = this.$store.state.payment.processTransactionResponse
      if (processTransactionResponse.complete) {
        try {
          this.$ga.event(
            'Payment',
            `Process Deposit`,
            `${this.transactionObj.methodCode.toUpperCase()} - ${processTransactionResponse.success ? 'Success' : 'Fail'}${
              !processTransactionResponse.success ? ` [Error : ${processTransactionResponse.code}]` : ''
            }`,
            processTransactionResponse.success ? this.transactionObj.amount : 0
          )
        } catch (err) {
          console.log(err)
        }
        this.processTransactionResponseDialog(processTransactionResponse)
        this.$store.dispatch(`${PAYMENT_RESET_PAYMENT_PROCESS_TRANSACTION_RESPONSE_STORE}`)
      }
    }
  },
  created() {
    this.checkOnlineDepositSuccessTransaction()
    this.preSelectBonusId()
    this.checkMemberTokenValidity()
    this.getPaymentMethod()
    this.$parent.getMemberBankAccount()
    this.getAvailableBonus()
    this.$parent.getBank()
    this.getConfigurationStatus()
    // this.getGatewaySupportedBank()
  },
  methods: {
    splitAmount(min, max, methodCode = '') {
      const range = []

      min = parseInt(min.toString(), 10)
      max = parseInt(max.toString(), 10)

      let currentAmount = min === 0 ? 200 : min
      //super request to fix amount options
      return [currentAmount, 500, 1000, 5000, 10000, 25000]
      //
      // //for BDT wallet preset the amount.
      // switch (methodCode.toUpperCase()) {
      //   case 'ROCKET':
      //   case 'NAGAD':
      //     return [currentAmount, 500, 1000, 5000, 10000, max]
      //   case 'BKASH':
      //     return [currentAmount, 500, 1000, 5000, 10000, max]
      //   default:
      //     return [currentAmount, 500, 1000, 5000, 10000, max]
      //     break
      // }
      //
      // let i = 1
      //
      // while (currentAmount * i <= max) {
      //   range.push((currentAmount *= i))
      //   if (i > 4) {
      //     break
      //   }
      //   i++
      // }
      // range.push(max)
      //
      // return range
    },
    preCheckIfBankAccountIsAdded() {
      if (this.memberBankAccounts.length <= 0) {
        this.promptAddBankAccount()
      }
    },
    changeDepositChannel(channel) {
      this.transactionObj.gatewayProviderCode = channel
      this.minAmount = this.selectedMethod.minAmount.filter(x => x.gatewayCode === channel)[0].minAmount
      this.maxAmount = this.selectedMethod.maxAmount.filter(x => x.gatewayCode === channel)[0].minAmount
      let allowAmount = this.splitAmount(this.minAmount, this.maxAmount)
      allowAmount.unshift(parseInt(this.minAmount.toString()))
      this.depositAmounts = allowAmount.filter(function(x, i, a) {
        return x != 0 && a.indexOf(x) === i
      })
      this.transactionObj.amount = this.depositAmounts.length > 0 ? this.depositAmounts[0] : 200
    },
    naviToDeposit() {
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT
      })
    },
    naviToWithdrawal() {
      this.$router.push({
        name: ROUTE_NAME.WITHDRAWAL
      })
    },
    copyTextGatewayName() {
      navigator.clipboard.writeText(this.transactionObj.gatewayName)
    },
    copyTextGatewayCode() {
      navigator.clipboard.writeText(this.transactionObj.gatewayCode)
    },
    copyTextAccountNumber() {
      navigator.clipboard.writeText(this.transactionObj.localAccountNumber)
    },
    copyTextAccountName() {
      navigator.clipboard.writeText(this.transactionObj.localAccountName)
    },
    applyDepositAmount(amount) {
      this.transactionObj.amount = amount
    },
    getAvailableMethodDisplayName(method) {
      let self = this
      let localization = method.localization.find(m => m.language == self.transactionObj.language)
      if (!localization) {
        return method.name
      }
      return localization.description
    },
    preSelectMemberBankAccount() {
      if (this.memberBankAccounts.length > 0) {
        let memberBankAccounts = this.memberBankAccounts.find(x => x.id != '0')
        if (memberBankAccounts != null && memberBankAccounts != undefined) {
          this.transactionObj.memberBankAccountId = memberBankAccounts.id
          this.transactionObj.memberBankName = memberBankAccounts.bankName
          this.transactionObj.memberBankAccountNumber = memberBankAccounts.accountNumber
          this.transactionObj.memberBankAccountName = memberBankAccounts.accountName
          this.transactionObj.memberBankCode = memberBankAccounts.bankCode
        } else {
          this.promptAddBankAccount()
        }
      } else {
        this.promptAddBankAccount()
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: null,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    promptAddBankAccount() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.info`)
      dialog.message.push(locale.getMessage(`message.please_add_bank_account`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.redirectToBankAccountPage()
      })
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    maximumImageSizeDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.info`)
      dialog.message.push(locale.getMessage(`message.image_too_large`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.closePageDialog()
      })
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    redirectToBankAccountPage() {
      this.closePageDialog()
      this.$router.push({
        name: ROUTE_NAME.BANK_ACCOUNT
      })
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    checkDepositAmount(bonusId) {
      this.bonusRequiredDepositAmount = ''
      let memberGroup = this.memberInfo.group
      let selectedApplyBonus = this.bonusList.find(x => x.id == bonusId)
      if (selectedApplyBonus) {
        let selectedApplyBonusMemberGroup = selectedApplyBonus.memberGroup.find(x => x.member_group.toLowerCase() == memberGroup.toLowerCase())
        if (selectedApplyBonusMemberGroup != null) {
          this.bonusRequiredDepositAmount = selectedApplyBonusMemberGroup.deposit_amount
        } else {
          this.bonusRequiredDepositAmount = selectedApplyBonus.depositAmount
        }
      }
    },
    getMerchantBankAccountsText(data) {
      return data.accountName
    },
    updateMoneyAmount(data) {
      this.transactionObj.amount = data.replace(/^0+/, '')
      this.checkDepostAmount()
    },
    validateBonusForm() {
      if (this.bonusList.length > 0) {
        return this.$refs.bonusForm.validate()
      }
      return true
    },
    checkDepostAmount() {
      if (this.bonusList.length > 0) {
        this.$refs.bonusForm.validate()
      }
    },
    checkOnlineDepositSuccessTransaction() {
      /* CHECK ROUTE FROM DEPOSIT SUCCESS */
      /* let transactionId = localStorage.getItem(SESSION.DEPOSIT_TRANSACTION_ID)
      let transactionAmount = localStorage.getItem(SESSION.DEPOSIT_AMOUNT)
      let transactionBonusId = localStorage.getItem(SESSION.DEPOSIT_BONUS_ID)
      let action = this.$route.params.action
      if (action != null && action != undefined && action != '' && action == 'applyBonus') {
        localStorage.removeItem(SESSION.DEPOSIT_TRANSACTION_ID)
        localStorage.removeItem(SESSION.DEPOSIT_AMOUNT)
        localStorage.removeItem(SESSION.DEPOSIT_BONUS_ID)
        this.applyBonus(transactionBonusId, transactionId, transactionAmount)
      } */
    },
    applyBonus(bonusId, transactionId, transactionAmount) {
      this.bonusTransactionAmount = transactionAmount
      this.bonusTransactionId = bonusId
      if (bonusId) {
        let applyBonusObj = {
          bonusId: bonusId,
          depositTransactionId: transactionId,
          depositAmount: transactionAmount
        }
        this.$store.dispatch(`${BONUS_APPLY_BONUS}`, {
          applyBonusObj
        })
      }
    },
    processTransactionResponseDialog(response) {
      if (response.code != 0 && !response.success) {
        this.msgDialog.show = false
        this.$parent.openAlert(false, locale.getMessage(`message.paymentError`))
      } else {
        // try{
        //   srtmCommands.push({
        //     event: 'track.betting.deposit',
        //     payload: {
        //       action: 'created',
        //       userId: localStorage.getItem(SESSION.USERNAME),
        //       transactionId: typeof response.data != 'undefined' ? response.data.trans_id : '',
        //       amount: this.transactionObj.amount,
        //       currency: SHARED.DEFAULT_CURRENCY
        //     }
        //   })
        //   // console.log('done sradar deposit , amount : '+this.transactionObj.amount)
        // }catch(e){console.log(e)}
        this.msgDialog.show = false
        window.scrollTo(0, 0)
        this.$parent.openAlert(true, locale.getMessage(`message.depositSubmitSuccess`))
        this.$refs.transactionForm.reset()
        this.$refs.bonusForm.reset()
        if (this.transactionObj.methodCode !== 'NLB') {
          if (this.transactionObj.gatewayProviderCode == 'DP') {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PAY_SECURE,
              query: {
                payment_url: response.data.payment_url,
                MerchantCode: response.data.post_body.MerchantCode,
                CustomerCode: response.data.post_body.CustomerCode,
                PhoneNumber: response.data.post_body.PhoneNumber,
                Currency: response.data.post_body.Currency,
                BankCode: response.data.post_body.BankCode,
                Amount: response.data.post_body.Amount,
                LanguageCode: response.data.post_body.LanguageCode,
                SourceAccNo: response.data.post_body.SourceAccNo,
                TransactionId: response.data.trans_id,
                Reference: response.data.post_body.Reference,
                Timestamp: response.data.post_body.Timestamp,
                ClientIP: response.data.post_body.ClientIP,
                Signature: response.data.post_body.Signature,
                ResponseURL: response.data.post_body.ResponseURL,
                BackendURL: response.data.post_body.BackendURL
              }
            })
            //uiHelper.openPopup(routeData.href, 'Pay Secure', 660, 550)

            this.$ga.event('DPay', 'Submit', 'Pending', 1)
            let gaCode = SHARED.GA_TRACKING_CODE
            let qString = routeData.href.split('?')[1] + '&ga=' + gaCode
            let jumpingUrl = '/static/html/payment/jumpingPage.html?' + qString
            window.open(jumpingUrl, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (this.transactionObj.gatewayProviderCode == 'AGPAY') {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PAY_SECURE,
              query: {
                payment_url: response.data.payment_url,
                MerchantId: response.data.post_body.MerchantId,
                CustomerId: response.data.post_body.CustomerId,
                CustomerPhoneNumber: response.data.post_body.CustomerPhoneNumber,
                CurrencyCode: response.data.post_body.CurrencyCode,
                BankCode: response.data.post_body.BankCode,
                Amount: response.data.post_body.Amount,
                SourceAccountNumber: response.data.post_body.SourceAccountNumber,
                TransactionId: response.data.trans_id,
                DepositReference: response.data.post_body.DepositReference,
                CreatedAt: response.data.post_body.CreatedAt,
                CustomerIP: response.data.post_body.CustomerIP,
                Signature: response.data.post_body.Signature,
                RedirectURL: response.data.post_body.RedirectURL,
                CallbackURL: response.data.post_body.CallbackURL,
                isAGPay: this.transactionObj.gatewayProviderCode == 'AGPAY' ? true : false
              }
            })
            //uiHelper.openPopup(routeData.href, 'Pay Secure', 660, 550)

            this.$ga.event('DPay', 'Submit', 'Pending', 1)
            let gaCode = SHARED.GA_TRACKING_CODE
            let qString = routeData.href.split('?')[1] + '&ga=' + gaCode
            let jumpingUrl = '/static/html/payment/jumpingPage.html?' + qString
            window.open(jumpingUrl, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (this.transactionObj.gatewayProviderCode == 'FAST') {
            this.$ga.event('FastPay', 'Submit', 'Pending', 1)
            let gaCode = SHARED.GA_TRACKING_CODE
            window.open(response.data.payment_url, 'Process Transaction', 'width=660,height=550', '_blank')
          } else {
            this.$ga.event('VTPay', 'Submit', 'Pending', 1)
            let processTransactionResponseType = response.data.process_type
            if (processTransactionResponseType === ProcessTransactionResponseType.OPEN_NEW_WINDOW) {
              window.open(response.data.payment_url, 'Process Transaction', 'width=660,height=550', '_blank')
            }
          }
        } else {
          let processTransactionResponseType = this.submittedTransactionResponse.processingType
          let processTransactionResponseData = response.data
          if (processTransactionResponseType == ProcessTransactionResponseType.HTML_AUTO_REDIRECT || processTransactionResponseType == ProcessTransactionResponseType.HTML_REDIRECT) {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.PROCESS_TRANSACTION,
              query: {
                html: processTransactionResponseData
              }
            })
            window.open(routeData.href, 'Process Transaction', 'width=660,height=550', '_blank')
          } else if (processTransactionResponseType == ProcessTransactionResponseType.URL_LINK || processTransactionResponseType == ProcessTransactionResponseType.IMAGE_URL) {
            if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
              window.location = processTransactionResponseData
            } else {
              uiHelper.openPopup(processTransactionResponseData, 'Process Transaction', 1200, 900)
            }
          } else if (processTransactionResponseType == ProcessTransactionResponseType.IMAGE_DATA) {
            this.qrCodeUrl = processTransactionResponseData
          } else {
            this.msgDialog.show = false
            this.$parent.openAlert(true, locale.getMessage(`message.depositSubmitSuccess`))
            if (this.$refs.localBankStepOneTransactionForm != undefined) {
              this.$refs.localBankStepOneTransactionForm.reset()
            } else {
              this.$refs.transactionForm.reset()
            }
            this.localBankDepositStep = 1
            window.scrollTo(0, 0)
            this.$refs.bonusForm.reset()
          }
        }
        try {
          this.fireDepositPixel()
          this.fireGnDepositPixel()
        } catch (err) {
          console.log(err)
        }
      }
      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
    },
    transactionComplete(response) {
      this.$store.dispatch(`${PAYMENT_RESET_TRANSACTION_RESPONSE_STORE}`)
      if (response.success) {
        this.submittedTransactionResponse = response.data
        if (this.submittedTransactionResponse.platformType == ProcessTransactionResponseType.HTML_AUTO_REDIRECT) {
          let epsProcessTransactionUrl = API.PAYMENT_URL + '/Transactions/' + response.id + '/ProcessTransaction'
          if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
            window.location = epsProcessTransactionUrl
          } else {
            window.open(epsProcessTransactionUrl, 'Process Transaction', 'width=660,height=550', '_blank')
          }
        } else {
          let processTransactionObj = {
            transactionId: response.id
          }
          this.$store.dispatch(PAYMENT_PROCESS_TRANSACTION, {
            processTransactionObj
          })
        }
        /* SAVE TRANSACTION INFO TO APPLY BONUS FOR ONLINE DEPOSIT */
        this.applyBonus(this.bonusObj.id, response.id, this.transactionObj.amount)
      } else {
        this.msgDialog.show = false
        if (response.code == 25.67) {
          this.$parent.openAlert(false, locale.getMessage(`message.localPaymentError`))
        } else if (response.code == '25.61.0' || response.code == '25.62.0' || response.code == '25.691' || response.code == '25.70.0' || response.code == '25.69') {
          this.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
        } else {
          this.$parent.openAlert(false, locale.getMessage(`message.paymentError`))
        }
      }
      this.transactionResponseResult = {
        success: response.success,
        complete: response.complete,
        code: response.code
      }
    },
    processPaymentTransaction() {
      let processTransactionObj = {
        transactionId: this.processTrxnId
      }
      this.$store.dispatch(PAYMENT_PROCESS_TRANSACTION, {
        processTransactionObj
      })
      this.onCloseClick()
    },
    changeDepositMethod(method, isBtnAction = false) {
      if (isBtnAction) {
        this.selectedMethod = method
        this.transactionObj.methodCode = method.code
        this.isDepositActive = true
      } else {
        this.isDepositActive = false
      }
      this.depositingBankFieldRequired = method && ['NLB', 'PN'].indexOf(method.code.toLowerCase()) > -1
      this.memberBankInfoRequired = method.code.toLowerCase() == 'olb'

      // const preferred_sort = [{ method_code: 'DP' }, { method_code: 'FAST' }].map(x => x.method_code)
      // this.gatewayChannel = method.gatewayProviderCode.sort(function(a, b) {
      //   return preferred_sort.indexOf(a.gatewayCode) - preferred_sort.indexOf(b.gatewayCode)
      // })
      this.gatewayChannel = method.gatewayProviderCode
      this.transactionObj.gatewayProviderCode = method.gatewayProviderCode[0].gatewayCode
      //fixed from google sheet item 33, do not clear amt when change method
      //this.transactionObj.amount = undefined
      if (method.code == 'NLB') {
        this.localBankDepositStep = 1
        this.preSelectMemberBankAccount()
        this.gatewayChannel = method.gatewayProviderCode

        this.transactionObj.gatewayAccountBankId = method.code
        this.transactionObj.localAccountName = this.gatewayChannel[0].bankAccount.account_name
        this.transactionObj.localAccountNumber = this.gatewayChannel[0].bankAccount.account_number
        this.transactionObj.merchantLocalBankCode = this.gatewayChannel[0].bankAccount.bank_account_code
        this.transactionObj.bank_name = this.gatewayChannel[0].bankAccount.bank_name
        this.transactionObj.branchCode = this.gatewayChannel[0].bankAccount.branch_code
        this.transactionObj.gatewayName = this.gatewayChannel[0].gatewayName
        this.transactionObj.gatewayCompanyBankId = typeof this.gatewayChannel[0].bankAccount.bank_id == 'undefined' ? null : this.gatewayChannel[0].bankAccount.bank_id
        this.transactionObj.ifscCode = this.gatewayChannel[0].bankAccount.ifsc_code
        this.minAmount = this.selectedMethod.minAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount
        this.maxAmount = this.selectedMethod.maxAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount

        this.depositAmounts = this.splitAmount(this.minAmount, this.maxAmount)
      } else {
        this.transactionObj.memberBankAccountId = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankAccountNumber = this.memberInfo.mobile.value
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.gatewayAccountBankId = method.code
        // this.transactionObj.gatewayAccountBankId = this.onlinePaymentSupportedBanks[0].code
        this.gatewayAccountBankName = method.name

        this.minAmount = this.selectedMethod.minAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount
        this.maxAmount = this.selectedMethod.maxAmount.filter(x => x.gatewayCode === method.gatewayProviderCode[0].gatewayCode)[0].minAmount
        let allowAmount = this.splitAmount(this.minAmount, this.maxAmount, method.code)
        allowAmount.unshift(parseInt(this.minAmount.toString()))
        this.depositAmounts = allowAmount.filter(function(x, i, a) {
          return x != 0 && a.indexOf(x) === i
        })
        this.transactionObj.amount = this.depositAmounts.length > 0 ? this.depositAmounts[0] : 200
      }
    },
    changeLocalBankMethod(method) {
      this.transactionObj.gatewayName = method.gatewayName
      this.transactionObj.gatewayCode = method.gatewayCode
      this.transactionObj.merchantLocalBankCode = method.bankAccount.bank_account_code
      this.transactionObj.localAccountName = method.bankAccount.account_name
      this.transactionObj.localAccountNumber = method.bankAccount.account_number
    },
    preSelectBonusId() {
      let bonusId = this.$route.params.bonusId
      if (bonusId) {
        this.bonusObj.id = bonusId
      }
    },
    updateBankAccountData(bankAccountObj) {
      if (bankAccountObj != null) {
        this.tempMemberBankAccountId = '0'
        this.transactionObj.memberBankAccountId = '0'
        this.transactionObj.memberBankAccountName = bankAccountObj.memberBankAccountName
        this.transactionObj.memberBankAccountNumber = bankAccountObj.memberBankAccountNumber
        this.transactionObj.memberBankName = bankAccountObj.memberBankName
        this.transactionObj.memberBankProvince = bankAccountObj.memberBankProvince
        this.transactionObj.memberBankBranch = bankAccountObj.memberBankBranch
        this.transactionObj.memberBankCity = bankAccountObj.memberBankCity
        this.transactionObj.saveNewBank = bankAccountObj.saveNewBank
      } else {
        this.transactionObj.memberBankAccountId = 0
        this.transactionObj.memberBankAccountName = ''
        this.transactionObj.memberBankAccountNumber = ''
        this.transactionObj.memberBankName = ''
        this.transactionObj.memberBankProvince = ''
        this.transactionObj.memberBankBranch = ''
        this.transactionObj.memberBankCity = ''
        this.transactionObj.saveNewBank = ''
      }
      this.closeNewBankAccountDialog()
    },
    proceedToMemberDepositDetailTab() {
      if (this.$refs.localBankStepOneTransactionForm.validate()) this.localBankDepositStep = 2
    },
    backToPreviousStepDetailTab() {
      this.localBankDepositStep = 1
    },
    makeNewTransaction() {
      this.selectedBankCodeValue = ''
      this.merchanctBankAccountNumber = ''
      this.localBankDepositStep = 1
      this.transactionObj = {
        /* TRANSACTION INFO */
        transactionType: SHARED.DEPOSIT,
        methodCode: '',
        amount: 0,
        depositDateTime: '',
        attachmentFileType: '',
        attachment: '',
        ipAddress: '',
        saveNewBank: false,
        /* MEMBER INFO */
        memberCode: localStorage.getItem(SESSION.USERNAME),
        memberRealName: '',
        currency: localStorage.getItem(SESSION.CURRENCY),
        memberBankAccountId: '',
        memberBankAccountName: '',
        memberBankAccountNumber: '',
        memberBankName: '',
        memberBankProvince: '',
        memberBankBranch: '',
        memberBankCity: '',
        memberEmail: '',
        memberContactIdorNumber: '',
        /* MERCHANT or ONLINE GATEWAY INFO */
        merchantBankCode: '',
        gatewayAccountId: '',
        gatewayAccountBankId: '',
        /* OTHER */
        transactionSuccessUrl: uiHelper.getHostname(),
        oriCurrency: '',
        oriAmount: '',
        language: uiHelper.getLanguage(),
        platform: uiHelper.getPlatform()
      }
      this.bonusObj.id = ''
      this.submittedTransactionResponse = null
      this.transactionResponseResult = {
        success: false,
        complete: false,
        code: 0
      }
      this.getPaymentMethod()
      this.$parent.getMemberBankAccount()
      this.getAvailableBonus()
      this.$parent.getBank()
      this.qrCodeUrl = ''
    },
    checkMemberTokenValidity() {
      let requiredLoading = false
      this.$store.dispatch(MEMBER_CHECK_TOKEN_VALIDITY, {
        requiredLoading
      })
    },
    getPaymentMethod() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        realName: this.memberInfo.name,
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${PAYMENT_AVAILABLE_METHOD}`, {
        obj
      })
    },
    getMerchantBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME),
        currency: localStorage.getItem(SESSION.CURRENCY)
      }
      this.$store.dispatch(`${PAYMENT_MERCHANT_BANK_ACCOUNT}`, {
        obj
      })
    },
    getMemberBankAccount() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_MEMBER_BANK_ACCOUNT}`, {
        obj
      })
    },
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getBonusDisplayText(e) {
      let bonusLocale = e.locale.find(x => x.language === this.currentLanguage)
      let percentageText = this.getPercentageText(e)
      let nominalText = ''
      if (bonusLocale) {
        nominalText = bonusLocale.title
      } else {
        let enLocale = e.locale.find(x => x.language === 'en')
        if (enLocale) {
          nominalText = enLocale.title
        } else {
          nominalText = e.name
        }
      }
      if (percentageText) {
        return `${nominalText} - ${percentageText}%`
      }
      return nominalText
    },
    getPercentageText(bonus) {
      if (bonus.formula != 'percentage') {
        return ''
      }
      if (!bonus.useReleaseTiers) {
        return bonus.percentGive
      }
      let currentMemberGroup = this.memberInfo.group
      let selectedApplyBonusMemberGroup = bonus.memberGroup.find(x => x.member_group.toLowerCase() == currentMemberGroup.toLowerCase())
      if (!selectedApplyBonusMemberGroup) {
        return bonus.percentGive
      }
      if (selectedApplyBonusMemberGroup.release_type != 2) {
        return ''
      }
      return parseFloat(selectedApplyBonusMemberGroup.percentage_given)
    },
    updateSelectedMemberBankAccount(data) {
      if (data != 0) {
        this.tempMemberBankAccountId = data
        let selectedMemberAccount = this.memberBankAccounts.find(x => x.id == data)
        this.transactionObj.memberBankAccountName = selectedMemberAccount.accountName
        this.transactionObj.memberBankAccountNumber = selectedMemberAccount.accountNumber
        this.transactionObj.memberBankName = selectedMemberAccount.bankName
        this.transactionObj.memberBankCode = selectedMemberAccount.bankCode
      } else if (data == 0) {
        this.openNewBankAccountDialog()
      }
    },
    uploadDepositAttachement() {
      var form = this
      let file = this.transactionObj.rawAttachment
      if (file != null || file != undefined) {
        var reader = new FileReader()
        reader.onload = function(e) {
          let size = e.total
          var image = new Image()
          image.onload = function() {
            // Resize the image using canvas
            if (size > 2000000) {
              form.maximumImageSizeDialog()
              form.$refs.fileimage.reset()
            }
            var canvas = document.createElement('canvas'),
              max_size = 700, // TODO : max size for a pic
              width = image.width,
              height = image.height
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width
                width = max_size
              }
            } else {
              if (height > max_size) {
                width *= max_size / height
                height = max_size
              }
            }
            canvas.width = width
            canvas.height = height
            canvas.getContext('2d').drawImage(image, 0, 0, width, height)
            //Getting base64 string;
            //var dataUrl = canvas.toDataURL('image/jpeg')
            //form.processBase64String(dataUrl)
          }
          // this will trigger image.onload
          image.src = e.target.result
        }
        reader.readAsDataURL(file)
      }
    },
    /* processBase64String(base64String) {
      if (base64String != null) {
        var base64BreakPoint = base64String.indexOf(',')
        this.transactionObj.attachment = base64String.slice(base64BreakPoint + 1)
        this.transactionObj.attachmentFileType = base64String.substr(0, base64BreakPoint)
      }
    },*/
    openNewBankAccountDialog() {
      if (this.transactionObj.memberBankAccountId == '0') {
        this.bankAccountDetail = {
          memberBankAccountId: this.transactionObj.memberBankAccountId,
          memberBankAccountName: this.transactionObj.memberBankAccountName,
          memberBankAccountNumber: '',
          memberBankName: this.transactionObj.memberBankName,
          memberBankProvince: this.transactionObj.memberBankProvince,
          memberBankBranch: this.transactionObj.memberBankBranch,
          memberBankCity: this.transactionObj.memberBankCity,
          saveNewBank: this.transactionObj.saveNewBank,
          memberCode: this.transactionObj.memberCode,
          isUpdateBank: false
        }
      } else {
        this.bankAccountDetail = null
        this.bankAccountDetail = {
          memberBankAccountNumber: '',
          memberBankAccountName: this.transactionObj.memberBankAccountName
        }
      }
      this.newBankAccountDialogShow = true
    },
    closeNewBankAccountDialog() {
      this.transactionObj.memberBankAccountId = this.tempMemberBankAccountId
      this.newBankAccountDialogShow = false
    },
    submitTransaction() {
      if (this.$refs.transactionForm.validate() && this.validateBonusForm() && this.isMethods_ChannelSelected()) {
        let obj = this.transactionObj
        this.setAmount = obj.amount
        if (obj.merchantBankCode == '0') {
          obj.merchantBankCode = `${this.selectedBankCodeValue}${this.merchanctBankAccountNumber}`
        }
        obj.memberRealName = this.memberInfo.name
        obj.transactionSuccessUrl = uiHelper.getHostname() + `/bank/deposit-success`
        this.$store.dispatch(`${PAYMENT_SUBMIT_PAYMENT_TRANSACTION}`, {
          obj
        })
      }
    },
    isMethods_ChannelSelected() {
      if (this.selectedMethod) {
        return true
      }
      return false
    },
    fireGnDepositPixel() {
      try {
        if (uiHelper.getCookie(SESSION.GN_CLICKID) != null) {
          let amount = this.setAmount
          let url = 'https://grindnetwork.go2cloud.org/aff_lsr?offer_id=10&amount=' + amount + '&transaction_id=' + uiHelper.getCookie(SESSION.GN_CLICKID)
          $.get(url, function(data) {
            console.log(data)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    fireDepositPixel() {
      try {
        if (uiHelper.getCookie(SESSION.UTM_S2) != null) {
          let url = 'https://egqqk.rdtk.io/postback?clickid=' + uiHelper.getCookie(SESSION.UTM_S2) + '&sum=10'
          $.get(url, function(data) {
            console.log(data)
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    memberBankAccountDisplayText(data) {
      if (data.value == 0) {
        return locale.getMessage(`label.${data.bankName}`)
      } else {
        return data.bankName
      }
    },
    getGatewaySupportedBank() {
      let obj = {
        memberCode: localStorage.getItem(SESSION.USERNAME)
      }
      this.$store.dispatch(`${PAYMENT_ONLINE_PAYMENT_SUPPORTED_BANK}`, {
        obj
      })
    },
    viewTransactionHistory() {
      this.$router.push({
        name: ROUTE_NAME.TRANSACTION,
        params: {
          tab: 'deposit'
        }
      })
    },
    routeToHome() {
      this.$router.push({
        name: ROUTE_NAME.HOME
      })
    },
    onCloseClick() {
      this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
      this.msgDialog.show = false
    },
    showConfirmationDialog() {
      let memberGroup = this.memberInfo.group
      let selectedApplyBonus = this.bonusList.find(x => this.bonusObj.id && x.id == this.bonusObj.id)
      if (selectedApplyBonus) {
        let selectedApplyBonusMemberGroup = selectedApplyBonus.memberGroup.find(x => x.member_group.toLowerCase() == memberGroup.toLowerCase())
        if (selectedApplyBonusMemberGroup != null) {
          if (selectedApplyBonus.formula == 'fixed') {
            this.bonusAmount = parseFloat(selectedApplyBonusMemberGroup.amount_given)
          } else {
            var calculatedAmount = (parseFloat(this.transactionObj.amount) * parseFloat(selectedApplyBonusMemberGroup.percentage_given)) / 100
            var maxAmount = selectedApplyBonusMemberGroup.maxAmount
            if (maxAmount) {
              this.bonusAmount = Math.min(maxAmount, calculatedAmount)
            } else {
              this.bonusAmount = calculatedAmount
            }
          }
        } else {
          if (selectedApplyBonus.formula == 'fixed') {
            this.bonusAmount = parseFloat(selectedApplyBonus.amountGive)
          } else {
            var calculatedAmount = (parseFloat(this.transactionObj.amount) * parseFloat(selectedApplyBonus.percentGive)) / 100
            var maxAmount = selectedApplyBonus.maxAmount
            if (maxAmount) {
              this.bonusAmount = Math.min(calculatedAmount, maxAmount)
            } else {
              this.bonusAmount = calculatedAmount
            }
          }
        }
        this.bonusTurnOver = (parseFloat(this.bonusAmount) + parseFloat(this.transactionObj.amount)) * parseFloat(selectedApplyBonus.turnoverMultiplier)
      } else {
        this.bonusTurnOver = parseFloat(this.transactionObj.amount)
        this.bonusAmount = 0
      }
      this.msgDialog.show = true
    },
    getConfigurationStatus() {
      this.$store.dispatch(`${DEPOSIT_PERCENTAGE_CONFIGURATION}`)
    }
  }
}
</script>

<style lang="scss">
.mobile-header {
  background-color: #000000;
  color: white;
  height: 50px;

  .mobile-header-item {
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected {
      border-bottom: 5px solid yellow;
    }
  }
}

.depositAmt-btn {
}

.mobile-depositAmt-btn-active {
  color: yellow !important;
}

.deposit-card {
  height: 80%;
  overflow-x: hidden;
  overflow-y: auto;
}

.deposit-tab {
  width: 150px;
  margin-right: 16px;
  padding: 10px;
  background-color: #fff;
  text-transform: capitalize;
}

.deposit-tab-mobile {
  width: 120px;
  margin-right: 10px;
  padding: 5px;
  background-color: #fff;
  text-transform: capitalize;
}

.card_container {
  margin-top: 70px;
  border-radius: 20px !important;

  &.nonBanking {
    margin-top: 0;
  }
}

.gateway-button {
  width: 80px;

  .v-btn__content {
    img {
      width: 44px;
      height: 44px;
    }
  }
}

.channelBtnImg {
  min-width: 70px;
  width: 70px;
  height: 20px;
}

.active-amount-button {
  color: #ffffff !important;

  &.v-btn--active {
    border-color: var(--v-newMobilePrimary-base) !important;
    background-color: var(--v-newMobilePrimary-base) !important;
  }

  &:before {
    opacity: 0 !important;
  }

  .v-btn__content {
    color: #ffffff !important;
  }
}

.active-gateway-button {
  color: #fff !important;

  &.v-btn--active {
    border: 2px solid #fddd04 !important;
    border-color: #fddd04 !important;
  }

  .v-btn__content {
    color: var(--v-primary-base) !important;
  }
}

.payment-dialog-title {
  color: #acacac;
}

.pay-info-group {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
}

.pay-info-group .bank-card.card-blue {
  background: url('/static/image/icon/bank_card.png') no-repeat center/cover, linear-gradient(135deg, #031a1f 0%, #07102f 100%);
}

.pay-info-group .bank-card-mobile.card-blue {
  background: url('/static/image/icon/bank_card.png') no-repeat center/cover, linear-gradient(135deg, #031a1f 0%, #07102f 100%);
}

.pay-info-group .bank-card {
  padding: 1.25rem 0;
  display: inline-block;
  vertical-align: middle;
}

.bank-card {
  position: relative;
  height: 12rem;
  padding: 1.25rem;
  border-radius: 0.9375rem;
  background-size: cover;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%), inset 0 -1px 1px rgb(0 0 0 / 50%), inset 0 1px 1px rgb(255 255 255 / 30%);
  color: #fff;
}

.bank-card-mobile {
  position: relative;
  height: 10rem;
  padding: 1.25rem;
  border-radius: 0.9375rem;
  background-size: cover;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%), inset 0 -1px 1px rgb(0 0 0 / 50%), inset 0 1px 1px rgb(255 255 255 / 30%);
  color: #fff;
}

.bank-card::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  width: 45px;
  height: 40px;
  background: url('/static/image/icon/wallet_chip.png') no-repeat top right/contain;
}

.bank-card-mobile::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 17px;
  right: 3%;
  width: 30px;
  height: 30px;
  background: url('/static/image/icon/wallet_chip.png') no-repeat top right/contain;
}

.bank-name {
  margin: 0px;
  padding-top: 0px;
}

.pay-info-group .bank-account {
  margin-bottom: 0.9375rem;
  padding-top: 3px;
}

.pay-info-group .bank-account-mobile {
  padding-top: 3px;
}

.bank-info-copy {
  position: relative;
  padding: 0 0.9375rem;
  padding-left: 12px;
  cursor: pointer;
}

.bank-name .bank-info-copy p,
.bank-name .bank-info-copy span {
  display: block;
  width: 70%;
  overflow: hidden;
  line-height: 30px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pay-info-group .bank-account p {
  font-size: 2rem;
}

.pay-info-group .bank-account-mobile p {
  font-size: 20px;
}

.bank-member p,
.bank-account p {
  width: 90%;
}

.bank-member p,
.bank-account-mobile p {
  width: 90%;
}

.bank-info-copy p,
.bank-info-copy span {
  line-height: 35px;
}

.bank-info-copy p {
  text-shadow: 0 0 2px rgb(0 0 0 / 50%);
}

.display-box {
  display: -webkit-box;
}

.bank-code-mobile {
  flex-wrap: nowrap;
  overflow-scrolling: touch;
  overflow: auto;
}

.bank-code {
  flex-wrap: nowrap;
}

.bank-code-mobile-button {
  margin-right: 5px;
}

.point.icon-color {
  color: whitesmoke;
}

@media (max-width: 959px) {
  .depositAmt-btn.theme-button {
    background-color: #333333 !important;
    color: white !important;
  }

  .deposit-btn.buttonPrimary {
    background-color: var(--v-newMobilePrimary-base) !important;
    color: white !important;
  }

  v-application.buttonPrimary {
    background-color: var(--v-newMobilePrimary-base) !important;
    color: white !important;
  }
}

@media (max-width: 599px) {
  .card_container {
    margin-top: 20px;
    border-radius: 20px !important;
  }

  .gateway-button {
    width: 80px !important;
  }

  .percent-label {
    left: 30px !important;
    width: 2.5rem !important;
  }
}

.icon_normal_pn {
  width: 38px;
  fill: #fff;
  color: #000;
  top: -35px;
  position: absolute;
  right: -29px;
}

.qrCode {
  img {
    margin: auto;
  }
}

.percent-tag {
  position: absolute;
  top: -0.28rem;
  left: -0.7125rem;
  width: 42px;
  height: 0.9375rem;
  border-radius: 0 0.1875rem 0.1875rem 0;
  background-color: #fddd04;
  box-shadow: 0 0 2px rgb(0 0 0 / 30%);
  text-align: center;
}

.percent-tag::before,
.percent-tag::after {
  content: '';
}

.percent-tag::before {
  position: absolute;
  z-index: 1;
  bottom: -4px;
  left: 0;
  width: 0;
  height: 0;
  border-width: 0 4px 4px 0;
  border-style: solid;
  border-color: transparent #112507 transparent transparent;
}

.percent-tag::after {
  position: absolute;
  z-index: 2;
  top: -0.0625rem;
  left: 0;
  width: 100%;
  height: 100%;
}

.percent-label {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: -1px;
  position: absolute !important;
  top: -0.28rem;
  left: 48px;
  font-family: 'Bebas Neue', Helvetica, Tahoma, sans-serif;
}

.pointer-cursor {
  cursor: pointer;
}
</style>
